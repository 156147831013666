import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, delay, mergeMap, retryWhen} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Inject, Injectable} from '@angular/core';
import {RollbarService} from '../services/rollbar.service';
import Rollbar from 'rollbar';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
  }

  private static DEFAULT_ERROR_MESSAGE = 'Could not connect to the server';
  private static DEFAULT_MAX_RETRIES = 0;
  private static DEFAULT_BACKOFF_MS = 1000;

  static getErrorMessage(errorMessage: string): string {
    return (!environment.production) ? errorMessage : HttpErrorInterceptor.DEFAULT_ERROR_MESSAGE;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const retryCount = request.method === 'GET' ? 2 : 0;
    return next.handle(request)
      .pipe(
        this.retryWithBackoff(1000, retryCount),
        catchError((error: HttpErrorResponse) => {
          return this.handleErrorNew(error);
        })
      );
  }

  private handleErrorNew(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (environment.production) {
      this.rollbar.error(error);
    }
    return throwError(HttpErrorInterceptor.getErrorMessage(errorMessage));
  }

  private retryWithBackoff(delayMs: number, maxRetry = HttpErrorInterceptor.DEFAULT_MAX_RETRIES, backoffMs = HttpErrorInterceptor.DEFAULT_BACKOFF_MS): (src: Observable<any>) => Observable<any> {
    let retries = maxRetry;
    return (src: Observable<any>) =>
      src.pipe(
        retryWhen((errors: Observable<any>) => errors.pipe(
          mergeMap(error => {
              if (retries-- > 0) {
                const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
                return of(error).pipe(delay(backoffTime));
              } else {
                return throwError(error);
              }
            }
          ))));
  }
}
