import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FileUploadEventsService } from '../file-upload-events.service';

@Injectable({
    providedIn: 'root'
})
export class HttpProgressInterceptor implements HttpInterceptor {

    constructor(
        private uploadEvents: FileUploadEventsService // my personal service for the progress bar - replace with your own
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.reportProgress) {
            // only intercept when the request is configured to report its progress
            const fileName = req.headers.get('x-file-name') || 'unknown';
            return next.handle(req).pipe(
                tap((event: HttpEvent<any>) => {
                    if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
                        // here we get the updated progress values, call your service or what ever here
                        this.uploadEvents.uploadProgress(fileName, Math.round(event.loaded / event.total * 100)); // display & update progress bar
                    } else if (event.type === HttpEventType.Response) {
                        this.uploadEvents.uploadComplete(fileName); // hide progress bar
                    }
                }, error => {
                    this.uploadEvents.uploadFailed(fileName, error); // hide progress bar
                })
            );
        } else {
            return next.handle(req);
        }
    }
}
