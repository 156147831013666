import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {RollbarService} from '../services/rollbar.service';
import Rollbar from 'rollbar';
import {environment} from '../../../environments/environment';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
  }

  handleError(error: any): void {
    const logFullApiErrorsToConsoleEnabled = !environment.production;
    if (logFullApiErrorsToConsoleEnabled) {
      console.error('Error:', error.originalError || error);
    }
    if (environment.production) {
      this.rollbar.error(error.originalError || error);
    }
  }
}
