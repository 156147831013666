import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {ADMIN_URL, BASE_URL} from '../../app-provider';
import { LocalStorageService } from '../../modules/storage/local-storage.service';
import { SessionStorageService } from '../../modules/storage/session-storage.service';
import { Practice } from '../../modules/practice-repository/entities/practice';
import {environment} from '../../../environments/environment';
import {WebsocketService} from '../services/websocket.service';
import {AuthenticationService} from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(@Inject(BASE_URL) protected baseUrl: string, @Inject(ADMIN_URL) protected adminUrl: string, private localStorage: LocalStorageService, private sessionStorage: SessionStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf(this.adminUrl) > -1 || request.url.indexOf(this.baseUrl) > -1) { return this.handleAccess(request, next); }
        return next.handle(request);
    }

    private handleAccess(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.sessionStorage.getItem('access_token');
        const selectedPractice = this.localStorage.getItem('selected_practice') as Practice;
        const installationId = this.localStorage.getItem('installation_id');
        const sessionId = this.sessionStorage.getItem('session_state');
        const appVersion = environment.version;
        const socketId = this.sessionStorage.getItem('socket_id');
        const headerSettings: { [name: string]: string | string[]; } = {};
        for (const key of request.headers.keys()) {
            headerSettings[key] = request.headers.getAll(key);
        }
        if (token) { headerSettings['Authorization'] = 'Bearer ' + token; }
        if (selectedPractice && selectedPractice.id) { headerSettings['x-practice-id'] = selectedPractice.id.toString(); }
        if (installationId) { headerSettings['x-installation-id'] = installationId; }
        if (sessionId) { headerSettings['x-session-id'] = sessionId; }
        if (socketId) { headerSettings['x-socket-id'] = socketId; }
        if (appVersion) { headerSettings['x-application-version'] = appVersion; }
        headerSettings['Content-Type'] = 'application/json';
        headerSettings['x-request-id'] = AuthenticationService.uuidv4();
        const newHeader = new HttpHeaders(headerSettings);
        const changedRequest = request.clone({
            headers: newHeader
        });
        return next.handle(changedRequest);
    }
}
