import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {AuthenticationService} from './core/auth/authentication.service';
import {LocalStorageService} from './modules/storage/local-storage.service';
import {AppPwaEventsService, PwaEvent} from './core/services/app-pwa-events.service';
import {Subscription} from 'rxjs';
import {CoreService} from './core/services/core.service';
import {DOCUMENT} from '@angular/common';
import {AuthEvent} from './core/auth/auth-event';
import {CoreEvent, CoreEventsService} from './core/services/core-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  pwaEventSubscription: Subscription;
  coreEventsSubscription: Subscription;
  authEventsSubscription: Subscription;
  errorAlertVisible = false;
  updateAlertVisible = false;
  offlineAlertVisible = false;
  isAuthenticated = false;

  constructor(
    private authService: AuthenticationService,
    private localStorage: LocalStorageService,
    private pwaService: AppPwaEventsService,
    private coreService: CoreService,
    private coreEventService: CoreEventsService,
    @Inject(DOCUMENT) private doc: Document) {
  }

  ngOnInit(): void {
    this.pwaEventSubscription = this.pwaService.pwaEvent$.subscribe(event => this.pwaEventHandler(event));
    this.coreEventsSubscription = this.coreEventService.events$.subscribe(event => this.coreEventsHandler(event));
    this.authEventsSubscription = this.authService.events$.subscribe(event => this.authEventsHandler(event));
    let installId = this.localStorage.getItem('installation_id');
    if (installId === null) {
      installId = AuthenticationService.uuidv4();
      this.localStorage.setItem('installation_id', installId);
    }
    this.themeHandler();
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  reload() {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.pwaEventSubscription?.unsubscribe();
    this.coreEventsSubscription?.unsubscribe();
    this.authEventsSubscription?.unsubscribe();
  }

  private authEventsHandler(event: AuthEvent) {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  private coreEventsHandler(event: CoreEvent) {
    if (event.type === 'connection_state_changed') {
      this.offlineAlertVisible = !this.coreService.online;
    }
    if (event.type === 'app_error') {
      this.errorAlertVisible = true;
    }
    if (event.type === 'settings_loaded') {
      this.themeHandler();
    }
  }

  private pwaEventHandler(event: PwaEvent) {
    if (event === 'update_available') {
      this.updateAlertVisible = true;
    }
    if (event === 'sw_error') {
      this.errorAlertVisible = true;
    }
  }

  private themeHandler() {
    const currentTheme = this.coreService.settings?.theme ?? '';
    const document = this.doc.documentElement;
    this.coreService.setTheme(document, currentTheme);
  }
}
